import { useState } from 'react';

import { Button, Card, CardContent, TextField, Typography } from '@/ui';

import BusinessPlan from './businessPlan';
import DirectPlan from './directPlan';


import type { User } from '@/types';
import type { FC } from 'react';

import { updateCustomer } from '@/api/payment';
import { useCurrentUser } from '@/hooks/useCurrentUser';

interface PlanDetailsProps {
  client: User;
}

const PlanDetails: FC<PlanDetailsProps> = ({ client }) => {
  const { user } = useCurrentUser();
  const [stripeId, setStripeId] = useState('');
  const isBusinessPlanType = client.isBusinessPaid;
  const hasNoPlan = !client.isPaid;
  const showStripeField =
    user.superAdmin && (hasNoPlan || !isBusinessPlanType);

  const handleSubmit = async () => {
    try {
      await updateCustomer(client, stripeId);
      window.location.reload();
    } catch (error) {
      // eslint-disable-next-line no-alert -- internal admin facing only
      alert(error instanceof Error ? error.message : 'An error occurred');
    }
  };

  if (hasNoPlan && !showStripeField) return null;

  return (
    <Card>
      <CardContent>
        {hasNoPlan && (
          <>
            <Typography variant="subtitle1">Plan Details</Typography>
            <Typography variant="caption">No plan</Typography>
            <br />
          </>
        )}
        {!hasNoPlan &&
          (isBusinessPlanType ? (
            <BusinessPlan client={client} />
          ) : (
            <DirectPlan client={client} />
          ))}
        {showStripeField && (
          <div className="mb-4">
            <TextField
              className="mr-2"
              label="Stripe Customer ID"
              value={stripeId}
              onChange={(e) => setStripeId(e.target.value)}
            />
            <Button variant="contained" onClick={handleSubmit}>
              Submit
            </Button>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default PlanDetails;
