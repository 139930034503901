import Case from 'case';

import { useGetSubscriptionDetails } from '@/hooks';
import { PaymentStatus } from '@/types';
import { Chip, Typography } from '@/ui';
import { getPlanDetails } from '@/utils';

import type { User } from '@/types';
import type { ChipProps } from '@/ui';
import type { FC } from 'react';

interface DirectPlanProps {
  client: User;
}

const DirectPlan: FC<DirectPlanProps> = ({ client }) => {
  const { data: planDetails, isLoading } = useGetSubscriptionDetails(
    client.userPayment?.subscriptionId,
  );
  const { planName, status, secondaryInfo } = getPlanDetails(
    client,
    planDetails,
  );

  if (isLoading) return null;

  let paymentStatusColor: ChipProps['color'];
  if (status === Case.title(PaymentStatus.ACTIVE)) {
    paymentStatusColor = 'primary';
  } else if (
    status === Case.title(PaymentStatus.CANCELED) ||
    status === Case.title(PaymentStatus.PAST_DUE)
  ) {
    paymentStatusColor = 'error';
  } else {
    paymentStatusColor = 'secondary';
  }

  return (
    <>
      <Typography className="mb-2 flex justify-between" variant="subtitle1">
        {planName} <Chip color={paymentStatusColor} label={status} />
      </Typography>
      <Typography variant="caption">{secondaryInfo}</Typography>
    </>
  );
};

export default DirectPlan;
