import { Link } from 'react-router-dom';

import { useSurvey } from '@/hooks';
import { SurveyStatus } from '@/types';
import { Button, Icon, Typography } from '@/ui';

import type { FC } from 'react';

interface HeaderProps {
  onShare: () => void;
}

const Header: FC<HeaderProps> = ({ onShare }) => {
  const { survey, isSurveyLoading } = useSurvey();

  return (
    <div className="body items-left mb-4 flex flex-col pt-2 text-cement-600 md:flex-row md:items-center md:justify-between">
      <span className="mr-4 xl:mr-auto">
        Only you can see this — review and share during a coaching session.
      </span>
      <div className="flex">
        <Link
          className="flex items-center text-black"
          to="https://app.mento.co/documents/templates/d7e43406-4a1f-4d88-9fbf-b90adab3e02d"
        >
          <Icon>open_in_new</Icon>
          <span className="mr-6">&nbsp;Coaching guide to Peer Feedback</span>
        </Link>
        <Button
          disabled={
            isSurveyLoading || survey.status !== SurveyStatus.COACH_REVIEWING
          }
          variant="contained"
          onClick={onShare}
        >
          <Icon>
            {survey.status === SurveyStatus.SHARED ? 'check' : 'lock'}
          </Icon>
          <Typography variant="body2">&nbsp;Share</Typography>
        </Button>
      </div>
    </div>
  );
};

export default Header;
