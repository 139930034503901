import MDEditor from '@uiw/react-md-editor';

import { Grid, Typography } from '@/ui';

import type { CoachProfile } from '@/types';
import type { FC } from 'react';

interface BragSheetProps {
  coachProfile: CoachProfile;
  onCoachProfileChange: (updated: CoachProfile) => void;
}

const BragSheet: FC<BragSheetProps> = ({
  coachProfile,
  onCoachProfileChange,
}) => {
  const onBragSheetChange = (value?: string) => {
    onCoachProfileChange({
      ...coachProfile,
      bragSheet: value || '',
    });
  };

  return (
    <Grid spacing={4} container>
      <Grid md={5} xs={12} item>
        <Typography className="mb-2" variant="h4">
          Brag Sheet
        </Typography>
        <Typography className="mb-1" variant="body1">
          Share your professional achievements, success stories, and notable
          accomplishments. This helps members understand your expertise and
          track record.
        </Typography>
      </Grid>
      <Grid className="mt-1 flex flex-col gap-3" md={7} xs={12} item>
        <div data-color-mode="light">
          <MDEditor
            height={300}
            value={coachProfile.bragSheet || ''}
            onChange={onBragSheetChange}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default BragSheet;
