export const VISIBLE_COMPANY_HEADERS = 2;
export const VISIBLE_COMPANY_MEMBER_HEADERS = 2;

export const COMPANY_HEADERS = [
  'Company Name',
  'Domains',
  'Status',
  'Members',
  'Updated',
  '',
];

export const COMPANY_MEMBER_HEADERS = [
  'Name',
  'Joined',
  'Coach',
  'Admin Note',
  'Growth Plan Started',
  'Insights Status',
  'Sessions',
];
