import { format } from 'date-fns';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { updateUser } from '@/api';
import {
  Avatar,
  Button,
  Icon,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@/ui';
import { capitalize } from '@/utils';

import CoachChip from './CoachChip';

import type { SessionCount, User } from '@/types';
import type { FC } from 'react';

import SessionColumn from '@/components/Company/Members/List/SessionColumn';

interface RowProps {
  sessionCount: SessionCount;
  user: User;
}

const Row: FC<RowProps> = ({ sessionCount, user }) => {
  const [adminNote, setAdminNote] = useState(user.adminNote || '');
  const [isSaving, setIsSaving] = useState(false);

  const hasStartedGrowthPlan = user.growthPlans[0]?.started || false;

  const insightStatus = capitalize(
    user.insightsStatus?.replace('_', ' ') || '',
  );

  const profileUrl = `/coach/members/${user.id}`;
  const upcomingSessionsUrl = `/coach/members/${user.id}/sessions/upcoming`;
  const pastSessionsUrl = `/coach/members/${user.id}/sessions/past`;

  const handleSaveNote = async () => {
    try {
      setIsSaving(true);
      await updateUser(user.id, { adminNote });
      setIsSaving(false);
    } catch (error) {
      console.error('Error saving admin note:', error);
      setIsSaving(false);
    }
  };

  return (
    <TableRow>
      <TableCell>
        <Link className="flex items-center gap-2 no-underline" to={profileUrl}>
          <Avatar
            firstName={user.firstName}
            lastName={user.lastName}
            src={user.avatarUrl ?? ''}
          />
          <div>
            <Typography color="text.primary" variant="subtitle1">
              {user.firstName} {user.lastName}
            </Typography>
            <Typography color="text.secondary" variant="caption">
              {user.profile?.title}
            </Typography>
          </div>
        </Link>
      </TableCell>
      <TableCell>
        <Typography color="text.secondary" variant="caption">
          {format(Date.parse(user.createdAt), 'MMM d yyyy')}
        </Typography>
      </TableCell>
      <TableCell>
        {user.coaches.length ? (
          <div className="flex flex-col items-start gap-1">
            {user.coaches.map((coach) => (
              <CoachChip
                key={`coach-avatar-${coach.id}-${user.id}`}
                coach={coach}
              />
            ))}
          </div>
        ) : (
          <Typography color="text.secondary" variant="caption">
            No coach assigned
          </Typography>
        )}
      </TableCell>
      <TableCell>
        <div className="flex items-center gap-2">
          <TextField
            placeholder="Admin note"
            rows={2}
            size="small"
            value={adminNote}
            multiline
            onChange={(e) => setAdminNote(e.target.value)}
          />
          <Button
            disabled={isSaving}
            size="small"
            variant="contained"
            onClick={handleSaveNote}
          >
            Save
          </Button>
        </div>
      </TableCell>
      <TableCell className="md:table-cell">
        {hasStartedGrowthPlan ? (
          <Icon color="success">check</Icon>
        ) : (
          <Icon color="warning">close</Icon>
        )}
      </TableCell>
      <TableCell className="md:table-cell">{insightStatus}</TableCell>
      <TableCell className="hidden md:table-cell">
        <SessionColumn
          pastSessionsUrl={pastSessionsUrl}
          timezone={user.profile?.timezone ?? 'America/Los_Angeles'}
          upcomingSessionsUrl={upcomingSessionsUrl}
          {...sessionCount}
        />
      </TableCell>
    </TableRow>
  );
};

export default Row;
