import mentoApi from './mentoApi';

import type { Price, StripeCheckoutSession, User, UserPayment } from '@/types';

export const stripeCheckout: (
  price: Price,
  user: User,
  successUrl: string,
  cancelUrl: string,
) => Promise<StripeCheckoutSession> = async (
  price,
  user,
  successUrl,
  cancelUrl,
) => {
  const productId =
    import.meta.env.VITE_APP_ENV === 'production'
      ? price.id.production
      : price.id.test;

  const result = await mentoApi.post(
    `/user_payments/${user.id}/stripe_session`,
    {
      cancelUrl,
      productId,
      successUrl,
    },
  );

  return result.data;
};

export const validateSubscription: (
  user: User,
) => Promise<UserPayment> = async (user: User) =>
  (await mentoApi.post(`/user_payments/${user.id}/validate_subscription`)).data;

export const createPortalLink = async (user: User) =>
  (await mentoApi.post(`/user_payments/${user.id}/portal_link`)).data;

// Admin only endpoint
export const updateCustomer = async (user: User, customerId: string) =>
  (
    await mentoApi.post(`/user_payments/${user.id}/update_customer`, {
      customerId,
    })
  ).data;

// This endpoint also unassigns coaches from users, only exists in client
export const pausePayment = async () =>
  // TODO: Figure out what we want to do with billing customer portal
  Promise.resolve({});
