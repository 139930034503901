import { mapSessionQueryParams } from '@/utils';

import mentoApi from './mentoApi';

import type { Session, SessionDashboard } from '@/types';
import type { GetUserSessionsOptions } from '@/utils';

export const getSessions = async (params: GetUserSessionsOptions) =>
  (await mentoApi.get(`/sessions?${mapSessionQueryParams(params)}`)).data;

export const getSessionCount = async (userId: string) =>
  (await mentoApi.get(`/sessions/count?user_id=${userId}`)).data;

export const getSession = async (id: string): Promise<Session> =>
  (await mentoApi.get(`/sessions/${id}`)).data;

export const updateSession = async (
  id: string,
  data: Partial<Session>,
): Promise<Session> => (await mentoApi.patch(`/sessions/${id}`, data)).data;

export const getSessionsDashboard = async (): Promise<SessionDashboard> =>
  (await mentoApi.get('/sessions/dashboard')).data;
