import { useLocation, useParams } from 'react-router-dom';

import { COMPANY_MEMBER_HEADERS } from '@/constants';
import { useCompanyMembers, useCompanySessions } from '@/hooks';
import { TableBody } from '@/ui';

import Empty from './Empty';
import Loading from './Loading';
import Row from './Row';

interface ListProps {
  sortDirection: 'asc' | 'desc';
  sortField: string;
}

const List: React.FC<ListProps> = ({ sortField, sortDirection }: ListProps) => {
  const { companyId = '' } = useParams();
  const { pathname } = useLocation();

  const isPastMembers = pathname.includes('past');

  // TODO: Update and paginate company management
  const { data: members = [], isLoading } = useCompanyMembers(
    companyId,
    !isPastMembers,
  );

  // Ideally this would happen in the Row/SessionColumn, and fetch count of sessions for each user
  // However, since member management is not paginated, this could trigger enough calls
  // to choke the browser. So we do it here, fetch all and send just what we need
  const { data: sessions = {}, isLoading: isLoadingSessions } =
    useCompanySessions(companyId);

  if (isLoading || isLoadingSessions) {
    return <Loading columns={COMPANY_MEMBER_HEADERS.length} />;
  }

  if (!members.length) {
    return <Empty columns={COMPANY_MEMBER_HEADERS.length} />;
  }

  // eslint-disable-next-line complexity -- this isn't actually complex we're just sorting by a bunch of things
  const sortedMembers = [...members].sort((a, b) => {
    let aValue;
    let bValue;

    switch (sortField) {
      case 'name':
        aValue = a.fullName.toLowerCase();
        bValue = b.fullName.toLowerCase();
        break;
      case 'joined':
        aValue = new Date(a.createdAt);
        bValue = new Date(b.createdAt);
        break;
      case 'adminnote':
        aValue = a.adminNote || '';
        bValue = b.adminNote || '';
        break;
      case 'coach':
        aValue = a.coaches[0]?.fullName.toLowerCase() || '';
        bValue = b.coaches[0]?.fullName.toLowerCase() || '';
        break;
      case 'growthplanstarted':
        aValue = a.growthPlans[0]?.started ? 1 : -1;
        bValue = b.growthPlans[0]?.started ? 1 : -1;
        break;
      case 'insightsstatus':
        aValue = a.insightsStatus || '';
        bValue = b.insightsStatus || '';
        break;
      case 'sessions':
        aValue =
          (sessions[a.id]?.pending || 0) * 100 + (sessions[a.id]?.done || 0);
        bValue =
          (sessions[b.id]?.pending || 0) * 100 + (sessions[b.id]?.done || 0);
        break;
      default:
        return 0;
    }

    if (aValue < bValue) return sortDirection === 'asc' ? -1 : 1;
    if (aValue > bValue) return sortDirection === 'asc' ? 1 : -1;
    return 0;
  });

  return (
    <TableBody>
      {sortedMembers.map((member) => (
        <Row
          key={`${member.id}-company-member`}
          sessionCount={sessions[member.id]}
          user={member}
        />
      ))}
    </TableBody>
  );
};

export default List;
