import mentoApi from './mentoApi';

import type { Company, CompanyStats, User } from '@/types';

export const getCompanies = async (search = '', page = 0) =>
  (await mentoApi.get(`/companies?search=${search}&page=${page}`)).data;

export const getCompany = async (id: string) =>
  (await mentoApi.get(`/companies/${id}`)).data;

export const getCompanyMembers = async (
  id: string,
  isActive: boolean = true,
): Promise<User[]> =>
  (
    await mentoApi.get(
      `/users?company_id=${id}&is_active=${isActive}&view=admin&limit=1000`,
    )
  ).data.items;

export const getCompanySessions = async (id: string) =>
  (await mentoApi.get(`/companies/${id}/sessions`)).data;

export const createCompany = async (name: string) =>
  (await mentoApi.post('/companies', { company: { name } })).data;

export const updateCompany = async (id: string, data: Partial<Company>) =>
  (await mentoApi.patch(`/companies/${id}`, { company: data })).data;

export const deleteCompany = async (id: string) =>
  (await mentoApi.delete(`/companies/${id}`)).data;

export const getCompanyStats = async (
  companyId: string,
): Promise<CompanyStats> =>
  (await mentoApi.get(`/companies/${companyId}/stats`)).data;
